export const HOME_URL = Object.freeze({
  path: '/',
  name: 'home',
  components: {
    path: '/components',
    name: 'home.components',
  },
});

export function holidays(year) {
  return [
    {
      start: `${year}-01-01T00:00:00-03:00`,
      allDay: true,
      overlap: false,
      display: 'background',
    },
    {
      start: `${carnavalSegunda(year)}T00:00:00-03:00`,
      allDay: true,
      overlap: false,
      display: 'background',
    },
    {
      start: `${carnavalTerca(year)}T00:00:00-03:00`,
      allDay: true,
      overlap: false,
      display: 'background',
    },
    {
      start: `${sextaFeiraSanta(year)}T00:00:00-03:00`,
      allDay: true,
      overlap: false,
      display: 'background',
    },
    {
      start: `${pascoaText(year)}T00:00:00-03:00`,
      allDay: true,
      overlap: false,
      display: 'background',
    },
    {
      start: `${year}-04-21T00:00:00-03:00`,
      allDay: true,
      overlap: false,
      display: 'background',
    },
    {
      start: `${year}-05-01T00:00:00-03:00`,
      allDay: true,
      overlap: false,
      display: 'background',
    },
    {
      start: `${corpusChristi(year)}T00:00:00-03:00`,
      allDay: true,
      overlap: false,
      display: 'background',
    },
    {
      start: `${year}-09-07T00:00:00-03:00`,
      allDay: true,
      overlap: false,
      display: 'background',
    },
    {
      start: `${year}-10-12T00:00:00-03:00`,
      allDay: true,
      overlap: false,
      display: 'background',
    },
    {
      start: `${year}-11-02T00:00:00-03:00`,
      allDay: true,
      overlap: false,
      display: 'background',
    },
    {
      start: `${year}-11-15T00:00:00-03:00`,
      allDay: true,
      overlap: false,
      display: 'background',
    },
    {
      start: `${year}-11-20T00:00:00-03:00`,
      allDay: true,
      overlap: false,
      display: 'background',
    },
    {
      start: `${year}-12-25T00:00:00-03:00`,
      allDay: true,
      overlap: false,
      display: 'background',
    },
  ];
}

function pascoaText(ano) {
  const dataPascoa = pascoa(ano);
  return `${ano}-${(dataPascoa.getMonth() + 1).toString().padStart(2, '0')}-${dataPascoa.getDate().toString().padStart(2, '0')}`;
}

/**
 * Cálculo da data da Páscoa pelo algoritmo de Meeus.
 */

function pascoa(ano) {
  const a = ano % 19;
  const b = parseInt(ano / 100);
  const c = ano % 100;
  const d = parseInt(b / 4);
  const e = b % 4;
  const f = parseInt((b + 8) / 25);
  const g = parseInt((b - f + 1) / 3);
  const h = ((19 * a) + b - d - g + 15) % 30;
  const i = parseInt(c / 4);
  const j = c % 4;
  const k = (32 + (2 * e) + (2 * i) - h - j) % 7;
  const l = parseInt((a + (11 * h) + 22 * k) / 451);

  const mes = parseInt((h + k - (7 * l) + 114) / 31);
  const dia = 1 + ((h + k - (7 * l) + 114) % 31);

  return new Date(ano, mes - 1, dia);
}

/**
 * O feriado da Sexta-Feira Santa (ou Sexta-Feira da Paixão) é 2 dias antes da Páscoa
 */
function sextaFeiraSanta(ano) {
  const dataPascoa = pascoa(ano);
  return `${ano}-${(dataPascoa.getMonth() + 1).toString().padStart(2, '0')}-${(dataPascoa.getDate() - 2).toString().padStart(2, '0')}`;
}

/**
 * A segunda-feira de Carnaval é 48 dias antes da Páscoa
 */
function carnavalSegunda(ano) {
  const dataPascoa = pascoa(ano);
  const dataCarnaval = new Date(dataPascoa.getFullYear(), dataPascoa.getMonth(), dataPascoa.getDate() - 48);
  return `${ano}-${(dataCarnaval.getMonth() + 1).toString().padStart(2, '0')}-${dataCarnaval.getDate().toString().padStart(2, '0')}`;
}

/**
 * A terça-feira de Carnaval é 47 dias antes da Páscoa
 */
function carnavalTerca(ano) {
  const dataPascoa = pascoa(ano);
  const dataCarnaval = new Date(dataPascoa.getFullYear(), dataPascoa.getMonth(), dataPascoa.getDate() - 47);
  return `${ano}-${(dataCarnaval.getMonth() + 1).toString().padStart(2, '0')}-${dataCarnaval.getDate().toString().padStart(2, '0')}`;
}

/**
 * O feriado de Corpus Christi é 60 dias depois da Páscoa
 */
function corpusChristi(ano) {
  const dataPascoa = pascoa(ano);
  const dataCorpusChristi = new Date(dataPascoa.getFullYear(), dataPascoa.getMonth(), dataPascoa.getDate() + 60);
  return `${ano}-${(dataCorpusChristi.getMonth() + 1).toString().padStart(2, '0')}-${dataCorpusChristi.getDate().toString().padStart(2, '0')}`;
}
